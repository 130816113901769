import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const RentCity = () => {
  const headingbanner = {
    title: `Rentcity`,
    content: `All-in-one property management tool`,
  };

  const data = {
    images: ["rentcity-1.webp", "rentcity-2.webp"],
    firstAlt: "rentcity",
    firstTitle: "rentcity",
    secondAlt: "rentcity",
    secondTitle: "rentcity",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Saas"],
      },
      {
        title: "What we did",
        text: ["User Research", "UI UX Designing"],
      },
      {
        title: "Platform",
        text: ["Web Application", "Mobile Responsive", "Mobile Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `Managing a diverse portfolio of properties has proven to be a time-consuming endeavor for property owners and managers using traditional methods.`,
          `The manual nature of property management tasks, from tracking rent payments to coordinating maintenance, has led to inefficiencies and stretched resources thin.`,
          `Late rent payments further exacerbate the challenge, requiring meticulous follow-up and increasing the risk of financial disruptions.`,
          `The issue is compounded by data dispersal across various systems, making it challenging to access a comprehensive overview of property performance.`,
          `Property management, encompassing tasks from tenant communication to financial analysis, becomes a complex juggling act.`,
          `The need for a streamlined communication solution became evident as managing multiple properties demanded a more organized and responsive approach to tenant interactions and maintenance requests.`
        ],
      },
      {
        title: "Solution",
        content: [
          `The platform facilitates seamless maintenance coordination by providing a dedicated portal for tenants to submit requests.`,
          `Property managers can efficiently assign tasks, track progress, and communicate updates—all within the platform, reducing the complexities associated with property maintenance.`,
          `To address maintenance issues before they escalate, the platform introduces proactive maintenance scheduling and alerts.`,
          `Property managers can set regular maintenance schedules, reducing the likelihood of major issues and ensuring the longevity of properties.`,
          `Additionally, the platform simplifies financial analysis by offering intuitive tools for expense tracking, profit and loss statements, and budgeting.`,
          `Complex financial data is presented in an accessible format, empowering property owners and managers to make informed decisions effortlessly.`
        ],
      },
    ],
    image: ["rentcity-problem.webp"],
  };

  const projectData = {
    title: "Slick and organized Platform for Managing Property",
    para: [
      `With the platform, you can easily upload your property details and can see all properties that you own or manage, maintain queries at single place, and create a lead for tenants.`,
      `The advanced features ensure that you can add multiple property at a single time making it easy for you to manage all at once.`,
      `Plus, the platform is easy to use and accessible from anywhere, making it a convenient solution for businesses of all sizes.`
    ],
    content: {
      image: ["rentcity-projectone.webp", "rentcity-projectone-2.webp"],
      para: [
        'We took a minimalistic approach for the property list page to provide tailor-made experience to the users.',
        'As soon as they login to the platform, the users can easily find property by searching. We have highlighted the add property feature to ensure focus on it.'
      ]
    },
    content2: {
        image: ["rentcity-projectone-3.webp"],
        para: [
          'The "Add Property" screen enhances user interaction by presenting a tailored experience that simplifies the process of entering property details. It intelligently guides users by suggesting specific information they need to provide.',
          'The interface incorporates a user-friendly feature for selecting the property size, allowing users to easily specify the type of unit and conveniently add details for multiple rooms.',
          'This thoughtful design not only streamlines the data entry process but also minimizes manual efforts for the user.'
        ]
      },
  };

  const projectData2 = {
    title: `Handle Your complex accounting easily and digitally`,
    para: [
      `The users can effortlessly manage their property finances with this intuitive accounting feature. They can track income and expenses, generate detailed financial reports, and stay on top of their property's financial health.`,
      `The user-friendly interface makes it easy to reconcile transactions, handle invoices, and streamline all the financial processes, allowing the users to focus on maximizing the property's profitability.`
    ],
    content: {
      image: ["rentcity-projecttwo.webp", "rentcity-projecttwo-2.webp"],
    },
  };

  const projectData3 = {
    title: `Effectively Manage Maintenance, Leads and vendors in one platform`,
    para: [
      `The users can effortlessly navigate and enhance their property management experience with this user-friendly interface and intuitive user experience.`,
      `The platform seamlessly integrates vendor management, lead tracking, and maintenance operations into a visually appealing and easy-to-use interface.`,
      `Helping users to enjoy a streamlined and efficient workflow as they interact with a thoughtfully designed UI that simplifies complex tasks.`
    ],
    content: {
      image: [
        "rentcity-projectthree.webp",
        "rentcity-projectthree-2.webp",
        "rentcity-projectthree-3.webp",
        "rentcity-projectthree-4.webp",
        "rentcity-projectthree-5.webp",
        "rentcity-projectthree-6.webp",
        "rentcity-projectthree-7.webp",
      ],
    },
  };

  const conclusionData = [
    {
      para: [
        `This property management platform marks a transformative leap in the realm of real estate management.`,
        `With a user-centric approach, we've seamlessly integrated complex functionalities, from maintenance and lead management to vendor coordination, into a singular, intuitive platform.`,
        `The implemented UX expertise ensures that every interaction is not just functional but also enjoyable, making property management a task that flows effortlessly.`,
        `We've put the power of streamlined operations directly into your hands, providing a solution that is not only comprehensive but also remarkably user-friendly.`,
        `As we close this chapter, envision a future where property management is synonymous with simplicity, efficiency, and a touch of sophistication—all made possible through thoughtful design and user experience expertise.`
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={42} />
      </div>
    </Layout>
  );
};

export default RentCity;

export const Head = () => (
  <Seo title="Rentcity- All-in-one Property Management Tool | Octet" description="All-in-one property management platform for which we conducted User Research and UI UX Design to simplify complex functionalities and offer smooth experience." />
)